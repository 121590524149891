import React from "react";
import { graphql, Link } from "gatsby";
import styled, { css } from "styled-components";
import { postPath, filterPosts } from "../utils";

import LargeTitle from "@components/LargeTitle";
import Layout from "@components/layout";
import MaxWidthWrapper from "@components/MaxWidthWrapper";
import Spacer from "@components/operator-lookup/Spacer";
const moment = require("moment");

const Post = ({ title, path, duration, excerpt }) => {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <PostWrapper
      to={path}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <TitleWrapper>
        <Title highlight={isHovered}>{title}</Title>
        <span style={{ float: "right" }}>{duration}</span>
      </TitleWrapper>
      <span>{excerpt}</span>
      <ReadMoreWrapper>
        Read more{" "}
        {isHovered && <span style={{ color: "var(--color-primary)" }}>⇒</span>}
      </ReadMoreWrapper>
    </PostWrapper>
  );
};

const PostWrapper = styled(Link)`
  display: inline-block;

  padding: 32px;
  border-radius: 7px;

  transition: background 0.25s linear;
  background: var(--color-foreground);
`;

const Title = styled.span`
  font-size: 20px;
  font-weight: var(--font-weight-bold);

  ${props =>
    props.highlight &&
    css`
      color: var(--color-primary);
    `}
`;

const TitleWrapper = styled.div`
  padding-bottom: 0.7em;
`;

const ReadMoreWrapper = styled.div`
  margin-top: 10px;
  font-weight: var(--font-weight-bold);
`;

const Posts = ({ data }) => {
  return (
    <Layout title={"Posts"} subpath={{ text: "posts" }} maxWidth="1100px">
      <Spacer size={20} />
      <LargeTitle
        text="Blog posts"
        subtitleHTML="Ramblings about OCaml tooling and design patterns."
      />
      <MaxWidthWrapper width={"1100px"}>
        <PostList>
          {filterPosts(data.posts.edges).map(({ node }, index) => {
            let { title, date } = node.frontmatter;
            date = moment(date);
            const path = postPath(title, date);

            const duration = `( ~ ${Math.round(
              node.fields.readingTime.minutes
            )} min )`;

            return (
              <Post
                path={path}
                title={title}
                duration={duration}
                excerpt={node.excerpt}
                key={index}
              />
            );
          })}
        </PostList>
      </MaxWidthWrapper>
      <Spacer size={20} />
    </Layout>
  );
};

const PostList = styled.div`
  padding-top: 2em;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 32px;
`;

export default Posts;

export const pageQuery = graphql`
  query {
    posts: allMdx(
      filter: { fileAbsolutePath: { regex: "/data/posts/[^/]*.md$/" } }
    ) {
      edges {
        node {
          excerpt
          body
          frontmatter {
            title
            date
            isPublished
          }
          fields {
            readingTime {
              minutes
            }
          }
        }
      }
    }
  }
`;
